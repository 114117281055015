var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"pa-2"},[_c('v-data-table',{attrs:{"headers":_vm.propHeaderMonth,"items":_vm.items,"search":_vm.propSearch,"loading-text":"Carregando...","loading":_vm.propLoading,"footer-props":{
        itemsPerPageOptions: [-1]
      },"hide-default-footer":"","fixed-header":"","multi-sort":"","dense":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
      var headers = ref.props.headers;
return [_c('thead',[_c('tr',[_c('th',{staticStyle:{"text-align":"center","background-color":"#90CAF9"},attrs:{"colspan":headers.length}},[_vm._v(" Mês ")])])])]}},{key:"item.total_alunos_mes",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.propNumberWeek(item, "total_alunos_"))+" ")])]}},{key:"body.append",fn:function(ref){
      var headers = ref.headers;
return [_c('tr',[_c('td',[_c('b',[_vm._v("Total")])]),_c('td',[_c('b',[_vm._v(_vm._s(_vm.items.reduce( function (sum, item) { return sum + (_vm.propNumberWeek(item, "total_alunos_") || 0); }, 0 )))])]),_c('td',[_c('b',[_vm._v(_vm._s(_vm.items.reduce( function (sum, item) { return sum + (_vm.propNumberWeek(item, "presenca_semana_") || 0); }, 0 )))])]),_c('td',[_c('b',[_vm._v(_vm._s(_vm.items.reduce( function (sum, item) { return sum + (_vm.propNumberWeek(item, "falta_semana_") || 0); }, 0 )))])]),_c('td',[_c('b',[_vm._v(" "+_vm._s(((_vm.items.reduce(function (sum, item) { return sum + _vm.propNumberWeek(item, "falta_semana_"); }, 0)) / ((_vm.items.reduce(function (sum, item) { return sum + _vm.propNumberWeek(item, "presenca_semana_"); }, 0) + _vm.items.reduce(function (sum, item) { return sum + _vm.propNumberWeek(item, "falta_semana_"); }, 0)) || 1) * 100).toFixed(2))+"% ")])]),_c('td',[_c('b',[_vm._v(_vm._s(_vm.items.reduce( function (sum, item) { return sum + (item.numero_turmas || 0); }, 0 )))])]),_c('td',[_c('b',[_vm._v(_vm._s(_vm.items.reduce( function (sum, item) { return sum + (item.evadidos || 0); }, 0 )))])]),_c('td',[_c('b',[_vm._v(_vm._s(_vm.items.reduce( function (sum, item) { return sum + (item.desistente || 0); }, 0 )))])]),_c('td',[_c('b',[_vm._v(_vm._s(_vm.items.reduce( function (sum, item) { return sum + (item.virada_livros || 0); }, 0 )))])]),_c('td',[_c('b',[_vm._v(_vm._s(_vm.items.reduce( function (sum, item) { return sum + (item.rematriculas || 0); }, 0 )))])])])]}},{key:"item.presenca_mes",fn:function(ref){
      var item = ref.item;
return [(_vm.propNumberWeek(item, 'presenca_semana_') > 0)?_c('span',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.propShow(item, 0, 3)}}},[_c('u',[_vm._v(_vm._s(_vm.propNumberWeek(item, "presenca_semana_")))])]):_c('span',[_vm._v(" "+_vm._s(_vm.propNumberWeek(item, "presenca_semana_"))+" ")])]}},{key:"item.falta_mes",fn:function(ref){
      var item = ref.item;
return [(_vm.propNumberWeek(item, 'falta_semana_') > 0)?_c('span',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.propShow(item, 0, 4)}}},[_c('u',[_vm._v(_vm._s(_vm.propNumberWeek(item, "falta_semana_")))])]):_c('span',[_vm._v(" "+_vm._s(_vm.propNumberWeek(item, "falta_semana_"))+" ")])]}},{key:"item.percentual_mes",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatPriceValue")(((_vm.propNumberWeek(item, "falta_semana_") / (_vm.propNumberWeek(item, "presenca_semana_") + _vm.propNumberWeek(item, "falta_semana_"))) * 100)))+"% ")]}},{key:"item.numero_turmas",fn:function(ref){
      var item = ref.item;
return [(item.numero_turmas > 0)?_c('span',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.propShow(item, 0, 5)}}},[_c('u',[_vm._v(_vm._s(item.numero_turmas))])]):_c('span',[_vm._v(" "+_vm._s(item.numero_turmas)+" ")])]}},{key:"item.evadidos",fn:function(ref){
      var item = ref.item;
return [(item.evadidos > 0)?_c('span',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.propShow(item, 0, 6)}}},[_c('u',[_vm._v(_vm._s(item.evadidos))])]):_c('span',[_vm._v(" "+_vm._s(item.evadidos)+" ")])]}},{key:"item.desistente",fn:function(ref){
      var item = ref.item;
return [(item.desistente > 0)?_c('span',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.propShow(item, 0, 7)}}},[_c('u',[_vm._v(_vm._s(item.desistente))])]):_c('span',[_vm._v(" "+_vm._s(item.desistente)+" ")])]}}],null,true)})],1),_c('v-row',{staticClass:"mt-3"},_vm._l((_vm.weeks),function(week,index){return _c('v-col',{key:index,attrs:{"cols":"12","xl":"4","md":"6","lg":"6","sm":"12"}},[_c('v-card',{staticClass:"pa-2"},[_c('v-data-table',{attrs:{"items":week,"headers":_vm.headersWeek,"search":_vm.propSearch,"loading-text":"Carregando...","loading":_vm.propLoading,"footer-props":{
            itemsPerPageOptions: [-1]
          },"hide-default-footer":"","fixed-header":"","multi-sort":"","dense":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
          var headers = ref.props.headers;
return [_c('thead',[_c('tr',[_c('th',{staticStyle:{"text-align":"center"},style:(("background-color: " + (_vm.statusColors(index)))),attrs:{"colspan":"5"}},[_vm._v(" "+_vm._s(_vm.showWeekHeader(index))+" ")])])])]}},{key:"item.total",fn:function(ref){
          var item = ref.item;
return [(item.total > 0)?_c('span',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.propShow(item, index + 1, 8)}}},[_c('u',[_vm._v(_vm._s(item.total))])]):_c('span',[_vm._v(" "+_vm._s(item.total)+" ")])]}},{key:"body.append",fn:function(ref){
          var headers = ref.headers;
return [_c('tr',[_c('td',[_c('b',[_vm._v("Total")])]),_c('td',[_c('b',[_vm._v(_vm._s(week.reduce( function (sum, item) { return sum + (item.total || 0); }, 0 )))])]),_c('td',[_c('b',[_vm._v(_vm._s(week.reduce( function (sum, item) { return sum + (item.presenca || 0); }, 0 )))])]),_c('td',[_c('b',[_vm._v(_vm._s(week.reduce( function (sum, item) { return sum + (item.falta || 0); }, 0 )))])]),_c('td',[_c('b',[_vm._v(" "+_vm._s(((week.reduce(function (sum, item) { return sum + item.falta; }, 0)) / ((week.reduce(function (sum, item) { return sum + item.falta; }, 0) + week.reduce(function (sum, item) { return sum + item.presenca; }, 0)) || 1) * 100).toFixed(2))+"% ")])])])]}},{key:"item.presenca",fn:function(ref){
          var item = ref.item;
return [(item.presenca > 0)?_c('span',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.propShow(item, index + 1, 1)}}},[_c('u',[_vm._v(_vm._s(item.presenca))])]):_c('span',[_vm._v(" "+_vm._s(item.presenca)+" ")])]}},{key:"item.falta",fn:function(ref){
          var item = ref.item;
return [(item.falta > 0)?_c('span',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.propShow(item, index + 1, 2)}}},[_c('u',[_vm._v(_vm._s(item.falta))])]):_c('span',[_vm._v(" "+_vm._s(item.falta)+" ")])]}},{key:"item.percentual",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatPriceValue")(item.percentual))+"% ")]}}],null,true)})],1)],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }